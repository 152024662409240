import styled from "styled-components";
import { Screens } from "@constants/screens";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Container = styled.section`
  padding: 112px 0 52px;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0 0;
  }
`;

const Header = styled.h1`
  font-family: ${Fonts.ClearfaceFont};
  font-size: 36px;
  line-height: 60px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 70px;

  @media (max-width: ${Screens.lg}) {
    font-size: 24px;
    line-height: 44px;
  }
`;

const PostsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  grid-gap: 30px;
  justify-content: space-between;

  @media (max-width: 1080px) {
    justify-content: space-around;
  }

  @media (max-width: 654px) {
    justify-content: center;
  }
`;

const PostWrapper = styled(AnchorLink)`
  width: 320px;
  margin-bottom: 60px;
  text-decoration: none;

  @media (max-width: ${Screens.md}) {
    margin-bottom: 50px;
  }

  @media (max-width: ${Screens.md}) {
    display: block;
    margin: 0 auto 60px;
  }
`;

const Category = styled.h4`
  color: ${Colors.beachTeal};
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  margin: 13px 0 0;
  text-transform: uppercase;
  width: 100%;
`;

const PostHeader = styled.h1`
  color: ${Colors.charcoal};
  font-family: ${Fonts.ClearfaceFont};
  text-align: left;
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  margin: 0;
`;

const Author = styled.p`
  color: ${Colors.charcoal};
  text-align: left;
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  margin: 15px 0 0;
`;

const Description = styled.p`
  color: ${Colors.charcoal};
  text-align: left;
  width: 86%;
  font-size: 18px;
  line-height: 25px;
  margin: 24px 0 0;
`;

const ImageContainer = styled.div`
  width: 320px;
  height: 180px;
  background: ${Colors.charcoal};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export {
  Container,
  Header,
  PostsContainer,
  PostWrapper,
  Category,
  PostHeader,
  Author,
  Description,
  ImageContainer,
  ButtonContainer,
};
