import React, { useState } from "react";
import { Wrapper, BlockSupTitle } from "@components/shared.styled";
import * as Colors from "@constants/colors";
import Image from "@components/image";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import encode from "@helpers/encode";
import {
  Header,
  StyledBlockHeader,
  Description,
  StyledWrapper,
  FormLabel,
  ButtonWrapper,
  FormButton,
  InputContainer,
  TextInput,
  ThanksMessage,
} from "./posts-intro.styled";

const PostsIntro = ({
  backgroundColor,
  smallHeader,
  largeHeader,
  description,
  formName,
  subscriptionLabel,
  subscriptionInputPlaceholder,
  sendIcon,
  thanksMessage,
}) => {
  const [isThanksMessageVisible, setIsThanksMessageVisible] = useState(false);
  const [email, setEmail] = useState("");

  const onFormSubmit = e => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": formName,
        email,
      }),
    });

    document.getElementById("subscribe-form").reset();
    setIsThanksMessageVisible(true);
  };
  return (
    <>
      <Header backgroundColor={backgroundColor}>
        <Wrapper>
          <BlockSupTitle color={Colors.beachTeal}>{smallHeader}</BlockSupTitle>
          <StyledBlockHeader>{largeHeader}</StyledBlockHeader>
          <Description
            dangerouslySetInnerHTML={{
              __html: convertMarkdownToHtml(description),
            }}
          />
        </Wrapper>
      </Header>
      <ButtonWrapper>
        <StyledWrapper>
          <form
            name={formName}
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={onFormSubmit}
            id="subscribe-form"
          >
            <input type="hidden" name="form-name" value={formName} />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            {isThanksMessageVisible ? (
              <ThanksMessage>{thanksMessage}</ThanksMessage>
            ) : (
              <>
                <FormLabel>{subscriptionLabel}</FormLabel>
                <InputContainer>
                  <TextInput
                    name="email"
                    id="email"
                    placeholder={subscriptionInputPlaceholder}
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  <FormButton type="submit">
                    <Image image={sendIcon} alt="Send button icon" />
                  </FormButton>
                </InputContainer>
              </>
            )}
          </form>
        </StyledWrapper>
      </ButtonWrapper>
    </>
  );
};

export default PostsIntro;
