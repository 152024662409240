import styled from "styled-components";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const FeaturedPost = styled(AnchorLink)`
  text-decoration: none;
  display: block;
  background: ${Colors.offWhite};
  padding: 112px 0 112px;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const Category = styled.h4`
  color: ${Colors.beachTeal};
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin: 43px 0 4px;
`;

const Header = styled.h1`
  font-family: ${Fonts.ClearfaceFont};
  font-size: 32px;
  line-height: 42px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 10px;
  color: ${Colors.charcoal};

  @media (max-width: ${Screens.md}) {
    font-size: 26px;
    line-height: 32px;
  }
`;

const Description = styled.p`
  color: ${Colors.charcoal};
  width: 80%;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto;

  @media (max-width: ${Screens.md}) {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export { FeaturedPost, Category, Header, Description, ImageWrapper };
