import styled from "styled-components";
import { Screens } from "@constants/screens";
import * as Colors from "@constants/colors";
import { BlockHeader, Wrapper } from "@components/shared.styled";

const Header = styled.section`
  background-color: ${({ backgroundColor = Colors.beachTealLighter }) =>
    backgroundColor};
  text-align: center;
  padding: 112px 0 112px;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const StyledBlockHeader = styled(BlockHeader)`
  max-width: 580px;
`;

const Description = styled.div`
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin: 40px auto 0;
  max-width: 580px;
`;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormLabel = styled.p`
  margin: 0 0 20px;
  color: ${Colors.charcoal};
  max-width: 100%;
  text-align: center;
  line-height: 24px;
`;

const ButtonWrapper = styled.div`
  padding: 48px 0;
  width: 100%;
  text-align: center;
  background: ${Colors.beachTealLighter};
`;

const ThanksMessage = styled.p`
  color: ${Colors.charcoal};
  margin: 0;

  ::after {
    content: "";
    display: block;
    margin: 20px auto 0;
    width: 32px;
    border-top: 4px solid ${Colors.charcoal};
  }
`;

const FormButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 260px;
  margin: 0 auto;

  @media (max-width: ${Screens.md}) {
    margin: 0 auto;
  }
`;

const TextInput = styled.input`
  border: none;
  height: 45px;
  background: ${Colors.white};
  color: ${Colors.charcoal};
  padding: 4px 50px 0 15px;
  width: 100%;
  font-size: 16px;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${Colors.charcoal};
    font-size: 16px;
    line-height: 25px;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${Colors.charcoal};
    font-size: 16px;
    line-height: 24px;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${Colors.charcoal};
    font-size: 16px;
    line-height: 24px;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${Colors.charcoal};
    font-size: 16px;
    line-height: 24px;
  }
`;

export {
  Header,
  StyledBlockHeader,
  Description,
  StyledWrapper,
  FormLabel,
  ButtonWrapper,
  FormButton,
  InputContainer,
  TextInput,
  ThanksMessage,
};
