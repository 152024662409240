import React from "react";
import { Wrapper } from "@components/shared.styled";
import Image from "@components/image";
import { ViewMoreButton } from "@components/shared.styled";
import {
  Container,
  Header,
  PostsContainer,
  PostWrapper,
  Category,
  PostHeader,
  Author,
  Description,
  ImageContainer,
  ButtonContainer,
} from "./posts-list.styled";

const PostsList = ({
  header,
  posts = [],
  maxItemsLength,
  isViewModeVisible,
  linkTemplate,
  loadNextPage,
  hasNextPage,
}) => (
  <>
    {posts.length > 0 && (
      <Container>
        <Wrapper>
          {header ? <Header>{header}</Header> : null}
          <PostsContainer>
            {posts
              .slice(0, maxItemsLength)
              .map(
                (
                  {
                    slug,
                    image,
                    category,
                    header: postHeader,
                    author,
                    description,
                  },
                  index
                ) => (
                  <PostWrapper key={index} to={`${linkTemplate}${slug}`}>
                    <ImageContainer>
                      <Image height="180px" image={image} />
                    </ImageContainer>
                    <Category>{category}</Category>
                    <PostHeader>{postHeader}</PostHeader>
                    {author ? <Author>By {author}</Author> : ""}
                    {description ? (
                      <Description>{description}</Description>
                    ) : (
                      ""
                    )}
                  </PostWrapper>
                )
              )}
          </PostsContainer>
          {isViewModeVisible && hasNextPage && (
            <ButtonContainer>
              <ViewMoreButton onClick={loadNextPage}>
                + View More
              </ViewMoreButton>
            </ButtonContainer>
          )}
        </Wrapper>
      </Container>
    )}
  </>
);

export default PostsList;
