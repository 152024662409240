import React, { useCallback, useState } from "react";
import { Wrapper } from "@components/shared.styled";
import Image from "@components/image";
import PostsList from "@modules/posts-list";
import { dummyItemSlug } from "@constants/dummy-item";
import {
  FeaturedPost,
  Category,
  Header,
  Description,
  ImageWrapper,
} from "./insights-posts.styled";
import { graphql, useStaticQuery, withPrefix } from "gatsby";

const InsightsPost = ({ featuredPost, postType, linkTemplate }) => {
  const result = useStaticQuery(
    graphql`
      {
        posts: paginatedCollectionPage(
          collection: { name: { eq: "posts-list" } }
          index: { eq: 0 }
        ) {
          nodes
          hasNextPage
          nextPage {
            id
          }
          collection {
            id
          }
        }
        reports: paginatedCollectionPage(
          collection: { name: { eq: "reports-list" } }
          index: { eq: 0 }
        ) {
          nodes
          hasNextPage
          nextPage {
            id
          }
          collection {
            id
          }
        }
      }
    `
  );
  const initialPage = result[postType];
  const collectionId = initialPage && initialPage.collection.id;
  const [latestPage, setLatestPage] = useState(initialPage);
  const initialNodes = initialPage
    ? initialPage.nodes.filter(item => item.slug !== dummyItemSlug)
    : [];

  const [postsListItems, setPostsListItems] = useState(initialNodes);

  const loadNextPage = useCallback(async () => {
    if (!latestPage.hasNextPage) return;

    const nextPageId = latestPage.nextPage.id;
    const path = withPrefix(
      `/paginated-${postType}-list/${collectionId}/${nextPageId}.json`
    );

    const res = await fetch(path);
    const json = await res.json();

    setPostsListItems(state => [...state, ...json.nodes]);
    setLatestPage(json);
  }, [latestPage, collectionId, postType]);

  return (
    <>
      <FeaturedPost to={`${linkTemplate}${featuredPost.slug}`}>
        <Wrapper>
          <ImageWrapper>
            <Image image={featuredPost.image} width="100%" alt="Post" />
          </ImageWrapper>
          <Category>{featuredPost.category}</Category>
          <Header>{featuredPost.header}</Header>
          <Description>{featuredPost.title}</Description>
        </Wrapper>
      </FeaturedPost>
      <PostsList
        isViewModeVisible
        linkTemplate={linkTemplate}
        posts={postsListItems}
        loadNextPage={loadNextPage}
        hasNextPage={latestPage && latestPage.hasNextPage}
      />
    </>
  );
};

export default InsightsPost;
